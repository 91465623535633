@mixin hover-focus-active {
    &:focus,
    &:active,
    &:hover {
        @content
    }
}

@mixin typography-headings {
    font-family: $font-family-headings;
    color: $font-color-primary;
    @include font-size(14);
    @include line-height(12);
    text-transform: uppercase;
    letter-spacing: 7px;
}

/* BACKGROUND GRADIENT */
@mixin background-gradient($startColor: #3C3C3C, $endColor: #999999) {
    background-color: $startColor;
    background-image: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
    background-image: -webkit-linear-gradient(top, $startColor, $endColor);
    background-image:    -moz-linear-gradient(top, $startColor, $endColor);
    background-image:     -ms-linear-gradient(top, $startColor, $endColor);
    background-image:      -o-linear-gradient(top, $startColor, $endColor);
    background-image:         linear-gradient(top, $startColor, $endColor);
    filter:            progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$startColor}', endColorStr='#{$endColor}');
}

/* BACKGROUND RADIAL */
@mixin background-radial($startColor: #FFFFFF, $startPos: 0%, $endColor: #000000, $endPos:100%) {
    background: -moz-radial-gradient(center, ellipse cover, $startColor $startPos, $endColor $endPos);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop($startPos,$startColor), color-stop($endPos,$endColor));
    background: -webkit-radial-gradient(center, ellipse cover, $startColor $startPos,$endColor $endPos);
    background: -o-radial-gradient(center, ellipse cover, $startColor $startPos,$endColor $endPos);
    background: -ms-radial-gradient(center, ellipse cover, $startColor $startPos,$endColor $endPos);
    background: radial-gradient(ellipse at center, $startColor $startPos,$endColor $endPos);
    filter:     progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$startColor}', endColorstr='#{$endColor}',GradientType=1 );
}

/* BACKGROUND SIZE */
@mixin background-size($width: 100%, $height: 100%) {
    -moz-background-size: $width $height;
    -webkit-background-size: $width $height;
    background-size: $width $height;
}

/* BORDER RADIUS */
@mixin border-radius($radius: 5px) {
    -moz-border-radius:    $radius;
    -webkit-border-radius: $radius;
    border-radius:         $radius;
}

@mixin border-radius-separate($topLeftRadius: 5px, $topRightRadius: 5px, $bottomLeftRadius: 5px, $bottomRightRadius: 5px) {
    -webkit-border-top-left-radius:     $topLeftRadius;
    -webkit-border-top-right-radius:    $topRightRadius;
    -webkit-border-bottom-right-radius: $bottomRightRadius;
    -webkit-border-bottom-left-radius:  $bottomLeftRadius;

    -moz-border-radius-topleft:     $topLeftRadius;
    -moz-border-radius-topright:    $topRightRadius;
    -moz-border-radius-bottomright: $bottomRightRadius;
    -moz-border-radius-bottomleft:  $bottomLeftRadius;

    border-top-left-radius:     $topLeftRadius;
    border-top-right-radius:    $topRightRadius;
    border-bottom-right-radius: $bottomRightRadius;
    border-bottom-left-radius:  $bottomLeftRadius;
}

/* BOX RGBA */
@mixin box-rgba($r: 60, $g: 3, $b: 12, $opacity: 0.23, $color: #3C3C3C) {
    background-color: transparent;
    background-color: rgba($r, $g, $b, $opacity);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$color}',endColorstr='#{$color}');
    zoom:   1;
}

/* BOX SHADOW */
@mixin as_box-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0,0,0,.4), $inset: "") {
    @if ($inset != "") {
        -webkit-box-shadow: $inset $x $y $blur $color;
        -moz-box-shadow:    $inset $x $y $blur $color;
        box-shadow:         $inset $x $y $blur $color;
    } @else {
        -webkit-box-shadow: $x $y $blur $color;
        -moz-box-shadow:    $x $y $blur $color;
        box-shadow:         $x $y $blur $color;
    }
}

/* BOX SIZING */
@mixin box-sizing($type: border-box) {
    -webkit-box-sizing:   $type;
    -moz-box-sizing:      $type;
    box-sizing:           $type;
}

/* COLUMNS */
@mixin columns($count: 3, $gap: 10) {
    -webkit-column-count: $count;
    -moz-column-count:    $count;
    column-count:         $count;

    -webkit-column-gap:   $gap;
    -moz-column-gap:      $gap;
    column-gap:           $gap;
}

/* DOUBLE BORDERS */
@mixin double-borders($colorOne: #3C3C3C, $colorTwo: #999999, $radius: 0) {
    border: 1px solid $colorOne;

    -webkit-box-shadow: 0 0 0 1px $colorTwo;
    -moz-box-shadow:    0 0 0 1px $colorTwo;
    box-shadow:         0 0 0 1px $colorTwo;

    @include border-radius( $radius );
}

/* FLEX */
@mixin flex($value: 1) {
    -webkit-box-flex: $value;
    -moz-box-flex:    $value;
    box-flex:         $value;
}

/* FLIP */
@mixin flip($scaleX: -1) {
    -moz-transform:    scaleX($scaleX);
    -o-transform:      scaleX($scaleX);
    -webkit-transform: scaleX($scaleX);
    transform:         scaleX($scaleX);
    filter:            FlipH;
    -ms-filter:        "FlipH";
}

/* FONT FACE */
@mixin font-face($fontFamily: myFont, $eotFileSrc: 'myFont.eot', $woffFileSrc: 'myFont.woff', $ttfFileSrc: 'myFont.ttf') {
    font-family: $fontFamily;
    src: url($eotFileSrc)  format('eot'),
    url($woffFileSrc) format('woff'),
    url($ttfFileSrc)  format('truetype');
}

@mixin opacity($opacity: 0.5 * 100) {
    filter:         alpha(opacity = $opacity);
    -ms-filter:     "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $opacity + ")";
    -moz-opacity:   $opacity;
    -khtml-opacity: $opacity;
    opacity:        $opacity;
}

/* OPACITY */
@mixin as_opacity($opacity: 0.5 * 100) {
    filter:         alpha(opacity = $opacity);
    -ms-filter:     "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $opacity + ")";
    -moz-opacity:   $opacity;
    -khtml-opacity: $opacity;
    opacity:        $opacity;
}

//@mixin font-size($sizeValue: 12 ){
//    font-size: $sizeValue + px; //fallback for old browsers
//    font-size: (0.125 * $sizeValue) + rem;
//}

@mixin line-height($heightValue: 12 ){
    line-height: $heightValue + px; //fallback for old browsers
    line-height: (0.125 * $heightValue) + rem;
}

/* OUTLINE RADIUS */
@mixin outline-radius($radius: 5px) {
    -webkit-outline-radius: $radius;
    -moz-outline-radius:    $radius;
    outline-radius:         $radius;
}

/* RESIZE */
@mixin resize($direction: both) {
    -webkit-resize: $direction;
    -moz-resize:    $direction;
    resize:         $direction;
}

/* reset list */
@mixin list-reset() {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* ROTATE*/
@mixin rotate($deg: 0, $m11: 0, $m12: 0, $m21: 0, $m22: 0) {
    -webkit-transform: rotate($deg + deg);
       -moz-transform: rotate($deg + deg);
        -ms-transform: rotate($deg + deg);
         -o-transform: rotate($deg + deg);
            transform: rotate($deg + deg);
    filter: progid:DXImageTransform.Microsoft.Matrix(
                    M11=#{$m11}, M12=#{$m12}, M21=#{$m21}, M22=#{$m22}, sizingMethod='auto expand');
    zoom: 1;
}

/* TEXT SHADOW */
@mixin text-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0,0,0,.4)) {
    text-shadow: $x $y $blur $color;
}

/* TRANSFORM  */
@mixin as_transform($params) {
    -webkit-transform: $params;
       -moz-transform: $params;
            transform: $params;
}

/* TRANSITION */
@mixin as_transition($what: all, $length: 1s, $easing: ease-in-out) {
    -moz-transition:    $what $length $easing;
    -o-transition:      $what $length $easing;
    -webkit-transition: $what $length $easing;
    -ms-transition:     $what $length $easing;
    transition:         $what $length $easing;

}

@mixin transition($what: all, $length: 1s, $easing: ease-in-out) {
    -moz-transition:    $what $length $easing;
    -o-transition:      $what $length $easing;
    -webkit-transition: $what $length $easing;
    -ms-transition:     $what $length $easing;
    transition:         $what $length $easing;
}

/* TRIPLE BORDERS */
@mixin triple-borders($colorOne: #3C3C3C, $colorTwo: #999999, $colorThree: #000000, $radius: 0) {
    border: 1px solid $colorOne;

    @include border-radius($radius);

    -webkit-box-shadow: 0 0 0 1px $colorTwo, 0 0 0 2px $colorThree;
    -moz-box-shadow:    0 0 0 1px $colorTwo, 0 0 0 2px $colorThree;
    box-shadow:         0 0 0 1px $colorTwo, 0 0 0 2px $colorThree;
}

/* ANIMATION */
@mixin animation($str) {
    -webkit-animation: $str;
    -moz-animation: $str;
    -ms-animation: $str;
    -o-animation: $str;
    animation: $str;
}

@mixin clearfix() {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

@mixin vertical-align {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}
}
