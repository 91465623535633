.social-icon {
    &__list {
        list-style: none;
        padding: 0;
        margin: 46px 0 0 30px;
        @include clearfix;

        @include min-screen(768px) {
            margin: 46px 0 0 108px;
        }

        li {
            float: left;
            margin-right: 18px;
        }
    }


    display: block;
    text-decoration: none; /* Remove underline from links */
    width: 26px;
    height: 26px;
    @include border-radius(50%);
    background-color: #2d2b2d;
    text-align: center;

    @include hover-focus-active {
        background-color: #c1232b;

        svg {
            color: #d2d2d2;
        }
    }

    svg {
        width: 13px;
        height: 13px;
        color: #d2d2d2;
        margin-top: -2px;
    }

    &__facebook {
        color: #fff;
    }
}

