body {
    background: #fff url('../images/trace-image-000.png') no-repeat left top;
    min-height: 800px;
}

//.artwork {
//    position: absolute;
//    top: 50px;
//    left: 500px;
//    width: 624px;
//
//    img {
//        display: block;
//        width: 100%;
//        height: auto;
//    }
//}

.logo {
    position: relative;
    margin-top: 104px;
    margin-left: 30px;

    @include min-screen(768px) {
        margin-left: 105px;
    }
}

.text-neka {
    position: relative;
    margin-top: 51px;
    margin-left: 30px;

    @include min-screen(768px) {
        margin-left: 103px;
    }
}

.text-kartini {
    position: relative;
    margin-top: 25px;
    margin-left: 30px;

    @include min-screen(768px) {
        margin-left: 103px;
    }
}

.signature {
    position: relative;
    margin-top: 24px;
    margin-left: 30px;

    @include min-screen(768px) {
        margin-left: 103px;
    }
}

.coming-soon {
    position: relative;
    margin-top: 53px;
    margin-left: 30px;

    @include min-screen(768px) {
        margin-left: 109px;
    }
}
